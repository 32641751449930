import * as React from "react";
import { ENQUIRY_STATE } from "../EnquiryV2Component";
import { Icon } from "@iconify/react";
import ReferFriendCardComponent from "./ReferFriendCard";

interface IReferFriendThanksComponent {
  [key: string]: any;
}

export const ReferFriendThanksComponent = (
  props: IReferFriendThanksComponent
) => {
  /* 
       --------------------
       Handlers function
       ---------------------
   */

  const onNext = () => {
    let payload = {
      currentState: ENQUIRY_STATE.REFER_FRIEND_THANKS,
    };
    props.onNext(payload);
  };

  return (
    <React.Fragment>
      <div
        className="flex flex-col justify-between h-full w-full lg:max-w-320 h-428p pt-3 lg:pt-0"
        style={{ height: "428px" }}
      >
        <div className="h-full w-full flex flex-col justify-between gap-2">
          <div>
            <Icon
              icon="teenyicons:tick-circle-solid"
              className="text-3xl mt-11 text-darkgreen"
            ></Icon>
            <h2 className="text-xl font-bold text-black">Thanks for sharing</h2>
            <div className="text-sm text-black-2 mt-1">
              We hope you and your friend(s) discover the perfect childcare
              center for your little ones! As a token of appreciation, enjoy a
              chance to win exclusive rewards when they enrol.
            </div>
          </div>
          <div className="flex flex-col gap-2.5 mt-8">
            {/* <button className="enquire-next-btn bg-light-200 flex gap-6 items-center justify-center font-medium text-xs text-black"
                            style={{ marginTop: "0px" }}>
                            <Icon icon="ic:baseline-verified"
                                className="text-berry text-xl"></Icon>
                            Enquire at Verified Centres nearby
                        </button> */}
            <a
              href="/child-care-subsidy-calculator"
              className=" enquire-next-btn bg-light-200 flex gap-1.5 items-center justify-center font-medium text-xs text-black"
              style={{ marginTop: "0px" }}
            >
              <Icon icon="ph:calculator" className="text-berry text-xl"></Icon>
              Calculate Childcare Subsidy
            </a>
            <button
              className="enquire-next-btn bg-rose hover:bg-darkrose flex items-center justify-center font-semibold text-base text-white"
              onClick={onNext}
              style={{ marginTop: "15px" }}
            >
              Done
            </button>
          </div>
        </div>
      </div>
      <ReferFriendCardComponent />
    </React.Fragment>
  );
};

export default ReferFriendThanksComponent;

import * as React from "react";
import { ENQUIRY_STATE } from "../EnquiryV2Component";

interface IEnquiryCommentsComponent {
    [key: string]: any;
}

export const EnquiryCommentsComponent = (props: IEnquiryCommentsComponent) => {
    // console.log(props)
    const { enquiryDetail } = props;
    const [isValidData, setValidData] = React.useState(true)
    const [comments, setComments] = React.useState('')

    React.useEffect(() => {
        if (enquiryDetail.comments) {
            setComments(enquiryDetail.comments)
        }

        return () => {
            setComments('')
        }
    }, [])
    /* 
      --------------------
        Handlers function
      ---------------------
      */
    const onNext = () => {
        if (isFormValid()) {
            setValidData(true);
            let payload = {
                currentState: ENQUIRY_STATE.COMMENTS,
                nextState: ENQUIRY_STATE.REVIEW,
                data: comments
            }
            props.onNext(payload);
        }
    };

    const isFormValid = () => {
        setValidData(true);
        let isValidData = true;
        enquiryDetail.about.map((item) => {
            if (item.label == "Other" && item.isActive && comments == "") {
                isValidData = false;
                setValidData(false);
            }
        })
        return isValidData
    }

    return (
        <div className="flex flex-col items-center w-full lg:max-w-320 enquire-form-side">
            <div className="step-meter bg-gray relative rounded-25">
                <span className="bg-gradient-blue absolute top-0 left-0 h-full rounded-25 w-80p"></span>
            </div>
            <div className="h-full w-full relative flex flex-col justify-between">
                <div className="w-full">
                    <div className="font-bold text-lg text-black-1 leading-19p">
                        Provide some basic information about your children and any other
                        questions you have
                    </div>
                </div>
                <div className="flex flex-col w-full mt-3 gap-3">
                    <textarea
                        className="font-medium text-sm px-2"
                        rows={8}
                        cols={50}
                        name="remarks"
                        placeholder="Hi there, I'd like to enquire about...."
                        value={comments}
                        onChange={(e) => {
                            setComments(e.target.value)
                        }}
                    ></textarea>
                </div>
                {
                    !isValidData &&
                    <span className="text-sm text-red-500">Please enter valid details.</span>
                }
                <div className={'mt-15'}>
                    <button
                        className="enquire-next-btn absolute bottom-0 bg-rose hover:bg-darkrose flex items-center justify-center font-semibold text-base text-white mt-5"
                        onClick={onNext}
                        style={{ marginTop: "20px" }}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EnquiryCommentsComponent;


import * as React from "react";
import { Icon } from '@iconify/react';
import { ENQUIRY_STATE } from "../EnquiryV2Component";
import { getTourAvailabilities } from "../service/LiveTourService";

interface IEnquiryDateOptionComponent {
    [key: string]: any
}

interface Slot {
    date: string;
    label: string;
    slot: string;
    active: boolean;
}


export const EnquiryDateOptionComponent = (props: IEnquiryDateOptionComponent) => {
    const [loading, setLoading] = React.useState(false);
    const { token,sendNewEventGa4 } = props
    const { liveTourAvailData } = props.enquiryDetail;
    const [liveTourModalInfo, setLiveTourModalInfo] = React.useState(liveTourAvailData)
    const [dateIndex, setDateIndex] = React.useState(0);
    const [timeActive, setTimeActive] = React.useState(-1);



    /* 
      --------------------
      Hooks
      ---------------------
    */
    React.useEffect(() => {
        let availabilityDetailsObject = transformData(liveTourModalInfo?.availabilityDetailsResponse, liveTourModalInfo?.startDate, liveTourModalInfo?.endDate)
        setLiveTourModalInfo(() => {
            return {
                ...liveTourAvailData,
                availabilityDetails: availabilityDetailsObject.map((item, index) => ({
                    ...item,
                    active: liveTourAvailData.availabilityDetails[index]?.active || false,
                    times: liveTourAvailData.availabilityDetails[index]?.times || availabilityDetailsObject[index]?.times,
                }))
            }
        })
        //setLiveTourModalInfo({
        //    ...liveTourModalInfo,
        //    availabilityDetails: availabilityDetailsObject
        //})
    }, [liveTourAvailData]);

    /* 
      --------------------
      Handlers function
      ---------------------
    */
    const next = () => {
        if (isFormValid()) {
            let tourDate;
            let tourTime;
            let slot;
            liveTourModalInfo?.availabilityDetails.map((availabilityDetail, index) => {
                if (availabilityDetail.active) {
                    tourDate = availabilityDetail.date
                    availabilityDetail.times.map((time) => {
                        if (time.active) {
                            tourTime = time.label
                            slot = time.slot
                        }
                    })
                }
            })
            let payload = {
                currentState: ENQUIRY_STATE.LIVE_TOUR_DATE_OPTION,
                nextState: ENQUIRY_STATE.LIVE_TOUR_REVIEW,
                data: {
                    ...liveTourModalInfo,
                    tourDate: tourDate,
                    tourTime: tourTime,
                    slot: slot
                }
            }
            sendNewEventGa4()
            props.onNext(payload)
        }
    };

    const isFormValid = () => {
        let isValidData = true;
        if (!liveTourModalInfo?.availabilityDetails.some(key => key.active)) {
            isValidData = false;
        }
        liveTourModalInfo?.availabilityDetails.map((availabilityDetail, index) => {
            if (availabilityDetail.active && !availabilityDetail.times.some(key => key.active)) {
                isValidData = false;
            }
        })
        return isValidData
    }

    const selectBookingDate = (index) => {
        let updatedData = { ...liveTourModalInfo }
        updatedData.availabilityDetails.map((item) => {
            item.active = false;
        })
        if (updatedData.availabilityDetails[index].times.length > 0) updatedData.availabilityDetails[index].active = true;
        setLiveTourModalInfo(updatedData);
    }

    const selectBookingTime = (dateIndex, timeIndex) => {
        let updatedData = { ...liveTourModalInfo }
        updatedData.availabilityDetails.map((item) => {
            item.times.map((time) => {
                time.active = false;
            })
        })
        updatedData.availabilityDetails[dateIndex].times[timeIndex].active = true;
        setLiveTourModalInfo(updatedData);
        setTimeActive(timeIndex)
    }

    const loadMoreDates = (type) => {
        if (liveTourModalInfo?.isDateTimeApiLoading) return;

        var currentDate = new Date();
        var sDate = liveTourModalInfo?.startDate == null ? new Date(new Date().setDate(new Date().getDate() - 7)) : new Date(liveTourModalInfo.startDate);
        var startDate;

        if (type == 1) {
            setDateIndex(dateIndex + 1);
            startDate = new Date(sDate.setDate(sDate.getDate() + 7)).toLocaleDateString('en-CA');
        } else if (type == -1 && dateIndex > 0) {
            setDateIndex(dateIndex - 1);
            startDate = new Date(sDate.setDate(sDate.getDate() - 7)).toLocaleDateString('en-CA');
        }

        var endDate = new Date(sDate.setDate(sDate.getDate() + 7)).toLocaleDateString('en-CA');

        setLiveTourModalInfo((state) => {
            state.startDate = startDate;
            state.endDate = endDate;
            return state;
        });

        setLoading(true);

        getTourAvailabilities(startDate, endDate, token)
            .then((res: any) => {

                var first6Days = new Date(new Date().setDate(new Date().getDate() + 7)).toLocaleDateString('en-CA');
                if (first6Days === sDate.toLocaleDateString('en-CA')) {
                    setLiveTourModalInfo({
                        ...liveTourModalInfo,
                        isBackDateTimeExists: false
                    })
                } else {
                    setLiveTourModalInfo({
                        ...liveTourModalInfo,
                        isBackDateTimeExists: true
                    })
                }

                if (res.data.availabilityDetails.length > 0) {
                    let availabilityDetailsObject = transformData(res.data.availabilityDetails, startDate, endDate)
                    setLiveTourModalInfo({
                        ...liveTourModalInfo,
                        availabilityDetails: availabilityDetailsObject,
                        isDateTimeApiLoading: false
                    })
                }

                setLoading(false);
                setTimeActive(-1);
            })
            .catch((error) => {
                setLoading(false);
                setTimeActive(-1);
            })

    }

    const transformData = (data, sDate, eDate) => {
        const startDate = new Date(sDate);
        const endDate = new Date(eDate);

        const availabilityDetails = [];


        for (const availability of data) {
            const startTime = new Date(availability.startTime);
            const endTime = new Date(availability.endTime);
            const date = startTime.toISOString().slice(0, 10);

            if (startTime >= startDate && endTime < endDate) {
                const time = {
                    date,
                    label: formatTime(startTime),
                    slot: availability.startTime,
                    active: false
                };



                const existingDate = availabilityDetails.find((item) => item.date === date);

                if (existingDate) {
                    existingDate.times.push(time);
                } else {
                    availabilityDetails.push({
                        date,
                        times: [time],
                    });
                }
            }
        }



        var data: any = [];

        while (startDate <= endDate) {
            var formattedDate = startDate.toISOString().slice(0, 10);
            var timesDetail = availabilityDetails.find(x => x.date == formattedDate);


            data.push({ date: formattedDate, format: formatDate(formattedDate), times: timesDetail != null ? timesDetail.times : [], active: false });

            startDate.setDate(startDate.getDate() + 1);
        }
        return data;
    }

    const formatTime = (dateTime) => {
        const date = new Date(dateTime);
        const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleTimeString('en-US', options);
    }

    const formatDate = (dateString) => {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const days = [
            "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
        ];

        const date = new Date(dateString);
        const dayCode = days[date.getDay()];
        const day = date.getDate().toString().padStart(2, "0");
        const month = months[date.getMonth()];

        return { dayCode, day, month };
    }


    function sortSlotsByAscending(slots: Slot[]): Slot[] {
        return [...slots].sort((a: Slot, b: Slot) => {
            const dateA = new Date(a.slot);
            const dateB = new Date(b.slot);
            return dateA.getTime() - dateB.getTime();
        });
    }

    return (

        <div className="enquire-form-side flex w-full flex-col items-center pt-6 lg:max-w-[400px] lg:pt-0" style={{
            paddingBottom: window.CurrentUserType !== null ? '79px' : '40px'
        }}>
            <style>{`
                body {
                    position: initial;
                }
                @media only screen and (max-width: 383px) {
                    body {
                        position: fixed;
                        width: 100%;
                    }
                    .date-option {
                        width: 100%;
                        overflow: auto;
                    }
                }
                .scrollbar-hide {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                }
                
                .scrollbar-hide::-webkit-scrollbar {
                    display: none;
                }
            `}</style>
            <div className="step-meter bg-gray relative rounded-25">
                <span className="bg-gradient-blue absolute top-0 left-0 h-full rounded-25 w-80p"></span>
            </div>
            <div className="h-full w-full flex flex-col justify-between">
                <div className="flex flex-col relative h-full gap-4">
                    <div>
                        <h3 className="font-bold text-lg text-black-1 leading-19p"
                            style={{ maxWidth: "240px" }}>
                            Which date would you like to book your live tour?
                        </h3>
                        <div className="mt-5">
                            <div className="flex gap-1 items-center date-option relative">
                                {liveTourModalInfo?.startDate !== new Date().toISOString().slice(0, 10) && (
                                    <Icon icon="mingcute:left-line" onClick={() => !loading && loadMoreDates(-1)}
                                        className={`text-black-1 cursor-pointer icon ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} style={{ minWidth: "1.5rem" }}>
                                    </Icon>)}

                                <div className="relative overflow-hidden">
                                    {loading && (
                                        <div className="absolute inset-0 flex items-center justify-center bg-white z-10"
                                            style={{ backgroundColor: `rgba(255, 255, 255, 0.5` }}>
                                            <span className="flex items-center gap-2">
                                                <img className="mix-blend-multiply" src="/img/navy-spinner.svg" alt="Spinner white dots circle" />
                                            </span>
                                        </div>
                                    )}

                                    <div className="overflow-x-auto scrollbar-hide">
                                        <div className="flex gap-1 justify-center" style={{ minWidth: 'min-content' }}>
                                            {liveTourModalInfo?.availabilityDetails.slice(0, 7).map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className={`calender-item flex flex-col items-center justify-center ${item.active ? 'selected' : ''} ${item.times.length > 0 ? 'available cursor-pointer' : ''}`}
                                                            onClick={() => selectBookingDate(index)}>
                                                            <span className="text-xxs">{item.format.dayCode}</span>
                                                            <span className="text-base font-bold">{item.format.day}</span>
                                                            <span className="text-xxs">{item.format.month}</span>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    </div>

                                </div>

                                <Icon
                                    icon="mingcute:right-line" onClick={() => !loading && loadMoreDates(1)}
                                    className={`text-black-1 cursor-pointer icon ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} style={{ minWidth: "1.5rem" }}
                                >
                                </Icon>
                            </div>

                        </div>


                    </div>

                    <div className="mt-3 flex gap-4 items-center text-xss">
                        <div className="flex gap-1 items-center">
                            <span className="green-dot"></span>
                            <span>Available dates</span>
                        </div>
                        <div className="flex gap-1 items-center">
                            <span className="blue-filled-dot"></span>
                            <span>Booking date selected</span>
                        </div>
                    </div>

                    <div className="mt-4">
                        <h3 className="font-bold text-lg text-black-1">Time</h3>
                        <div className="mt-2 flex gap-1 flex-wrap">
                            {!loading && sortSlotsByAscending(liveTourModalInfo?.availabilityDetails).map((item: any, dateIndex: number) => {
                                return (
                                    <React.Fragment key={item.date}>
                                        {item.active && item.times.map(function (time, timeIndex) {
                                            return (
                                                item.active &&
                                                <div key={time.label}>
                                                    <button className={`shift-pill-sm green-pill-outline ${time.active ? 'active' : ''}`} onClick={() => selectBookingTime(dateIndex, timeIndex)}>{time.label}</button>
                                                </div>
                                            )
                                        })
                                        }
                                    </React.Fragment>
                                )
                            })}



                        </div>
                    </div>
                    {/*<button className="enquire-next-btn bottom-0 bg-rose flex items-center justify-center font-semibold text-base text-white" onClick={next}>*/}
                    {/*    Next*/}
                    {/*</button>*/}
                    <div className="flex bottom-0 w-full flex-col gap-2">
                        <button
                            disabled={timeActive < 0}
                            className={`${timeActive < 0 ? 'btn-disabled' : 'bg-rose'} enquire-next-btn bottom-0 flex items-center justify-center font-semibold text-base text-white mt-5`}
                            onClick={next}
                            style={{ marginTop: 0 }}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EnquiryDateOptionComponent;
import axios from "axios";

export const getTourAvailabilities = (startDate, endDate, token) => {
        
        let data = {
                token: token,
                startDate: startDate,
                endDate: endDate
        }
        let headers = {
                'Content-Type': 'application/json',
        }
        console.log('api service called');
        let url = '/enquiry/getlivetouravailabilities';
        const json = JSON.stringify(data)
       return axios
        .post(url, json, { headers: headers })
}

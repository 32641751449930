import * as React from "react";
import { Icon } from "@iconify/react";
import { ENQUIRY_STATE } from "../EnquiryV2Component";

const initialOptions = [
    {
        label: "Request a tour",
        id: 2,
        steps: 1,
        isActive: false,
        icon: "majesticons:chat-line",
    },
    {
        label: "Fees",
        id: 3,
        steps: 2,
        isActive: false,
        icon: "solar:chat-round-money-outline",
    },
    {
        label: "Availability",
        id: 1,
        steps: 3,
        isActive: false,
        icon: "ic:outline-check-circle",
    },

    {
        label: "Program",
        id: 6,
        steps: 4,
        isActive: false,
        icon: "clarity:blocks-group-line",
    },
    {
        label: "Other",
        id: 5,
        steps: 5,
        isActive: false,
        icon: "ant-design:question-circle-outlined",
    },
];

const LIVE_TOUR = {
    label: "Live Tour Booking",
    id: 4,
    steps: 3,
    isActive: false,
    icon: "material-symbols:tour-outline",
};

interface IEnquiryAboutProps {
    [key: string]: any;
}

function EnquiryAbout(props: IEnquiryAboutProps) {
    const { isLiveTourBookingAvailable, enquiryDetail, sendNewEventGa4 } = props;
    const [items, setItems] = React.useState(JSON.parse(JSON.stringify(initialOptions)));
    const [errorMessage, setErrorMessage] = React.useState("")

    /* 
      --------------------
      Hooks
      ---------------------
    */
    React.useEffect(() => {
        if (isLiveTourBookingAvailable) {
            let tourObj = JSON.parse(JSON.stringify(LIVE_TOUR))
            setItems([tourObj, ...items])
           

        }
      

        if (enquiryDetail?.about?.length > 0) {
            setStateFromProps()
        }
        return () => {
        
            setItems([...initialOptions])
        }
    }, [])
    function getActiveLabelsString(array) {
        return array
            .filter(item => item.isActive)
            .map(item => item.label)
            .join('-');
    }
    const setStateFromProps = () => {
        let tourObj = JSON.parse(JSON.stringify(LIVE_TOUR))
        const clonedItems = isLiveTourBookingAvailable ? [tourObj, ...items] : [...items];
        enquiryDetail.about.map((item, i) => {
            let indexToBeUpdate = clonedItems.findIndex(key => key.id === item.id)
            clonedItems[indexToBeUpdate].isActive = item.isActive;
        })
        setItems(clonedItems)
    }


    /* 
      --------------------
      Handlers function
      ---------------------
    */
    const onItemClick = (id: number) => {
        let clonedItems = [...items];
        let index = clonedItems.findIndex((key) => key.id === id);
        const liveTourBookingIndex = clonedItems.findIndex((key) => key.id === 4);
        clonedItems[index].isActive = !clonedItems[index].isActive;
     
        if (clonedItems[index].id === 4 && clonedItems[index].isActive) {
            const newArray = clonedItems.map((item) => {
                if (item.id !== 4) {
                    return { ...item, isActive: false }
                } 
                    return {...item}
               
            })
            clonedItems = newArray
            

        } else if (clonedItems[index].id !== 4 && clonedItems[index].isActive === true && liveTourBookingIndex >= 0 && clonedItems[liveTourBookingIndex].isActive === true) {
            clonedItems[liveTourBookingIndex].isActive = false
         

        }
        
        setErrorMessage('')
        setItems(clonedItems);
      
        
    };

    const onNext = () => {
        if (isItemSelected()) {
            sendNewEventGa4(getActiveLabelsString(items))
            let selectedOptions = items.filter(item => item.isActive)
            let payload = {
                currentState: ENQUIRY_STATE.ABOUT,
                nextState: ENQUIRY_STATE.PROFILE,
                data: selectedOptions
            }
            props.onNext(payload);
        } else {
            setErrorMessage('Please select any one option.')
        }
    };

    /* 
    --------------------
    Handlers function
    ---------------------
    */
    const isItemSelected = () => {
        return items.some(key => key.isActive)
    }
    // console.log('Items=',items);

    return (
        <div className="enquire-form-side relative flex w-full flex-col items-center lg:max-w-320">
            <div className="step-meter bg-gray rounded-25 relative">
                <span className="bg-gradient-blue rounded-25 w-20p absolute left-0 top-0 h-full"></span>
            </div>
            <div className="w-full">
                <div className="text-black-1 leading-19p font-bold text-[22px]">
                    What would you like to enquire about?
                </div>
            </div>
            <div className="grid-cols-2 mt-3 grid w-full gap-2.5">
                {items.map((item, index) => {
                    return (
                        <div
                            className={`enquire-type-card flex flex-col gap-2 items-center justify-center ${item.isActive ? " bg-sky-300 " : " bg-light-200 "
                                }`}
                            onClick={() => onItemClick(item.id)}
                            key={item.id}
                        >
                            <Icon icon={item.icon} className="text-berry text-1xl"></Icon>
                            <span className="text-black-1 text-xs font-bold">
                                {item.label}
                            </span>
                        </div>
                    );
                })}
            </div>
            <div className="bottom-0 flex w-full flex-col gap-2">
                <span className="text-sm text-red-500"> {errorMessage}</span>
                <button
                    className="enquire-next-btn bg-rose flex items-center justify-center text-base font-semibold text-white hover:bg-darkrose"
                    onClick={onNext}
                    style={{ marginTop: 0 }}
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default EnquiryAbout;

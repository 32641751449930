
import * as React from "react";
import { ENQUIRY_STATE } from "../EnquiryV2Component"
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import ReferFriendCardComponent from "./ReferFriendCard";
import { env } from '../../../environment';
interface IEnquiryReviewComponent {
    [key: string]: any
}

export const ReferFriendReview = (props: IEnquiryReviewComponent) => {
    const { enquiryDetail } = props
    const { referFriendModal, formSubmitData } = enquiryDetail;
    const [errorMessage, setErrorMessage] = React.useState('')
    const [isVerified, setIsVerified] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [recaptchaResult, setReCaptchaResult] = React.useState('');
    const recaptchaRef = React.createRef();

    /* 
        --------------------
        Handlers function
        ---------------------
    */

    const onNext = () => {
        // API CALL
        setLoader(true)
        let payload = referFriendModal.enquiryForm;
        let headers = {
            'Content-Type': 'application/json',
        }

        const json = JSON.stringify({...payload, EnquiryId: formSubmitData.enquiryId, recaptchaResult: recaptchaResult});
        axios.post('/enquiry/enquiryreferral', json, { headers: headers }).then((response: any) => {
            if(response.data.isSuccess) {
                let propsPayload = {
                    currentState: ENQUIRY_STATE.REFER_FRIEND_REVIEW,
                    nextState: ENQUIRY_STATE.REFER_FRIEND_THANKS,
                }
                props.onNext(propsPayload);
                setLoader(false)
            } else {
                setErrorMessage(response?.data?.errors[0])
                setLoader(false)
            }
        }).catch((err) => {
            setLoader(false)
            setErrorMessage(err.message)
            console.log(err);

        })
    };

    const HandleVerify = (token) => {
        if (token) {
            setReCaptchaResult(token);
            setIsVerified(true);
        } else {
            setIsVerified(false);
        }
    }

    // console.log(props);

    return (
        <React.Fragment>
            <div className="flex flex-col relative items-center w-full lg:max-w-320 enquire-form-side">
                <div className="step-meter bg-gray relative rounded-25">
                    <span className="bg-gradient-blue absolute top-0 left-0 h-full rounded-25 w-full"></span>
                </div>
                <div className="h-full w-full flex flex-col relative justify-between overflow-y-auto" style={{ height: "350px" }}>
                    <div className="flex flex-col relative justify-between gap-4 pb-8">
                        <h3 className="font-bold text-lg text-black-1"
                            style={{ maxWidth: "240px" }}>
                            Review
                        </h3>
                        <span className="text-sm text-red-500"> {errorMessage}</span>
                        <div className={`${errorMessage=='' ? "mt-m20p" : ""}`}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={env.GOOGLE_SITE_KEY}
                                onChange={token => HandleVerify(token)}
                            />
                        </div>
                        {
                            referFriendModal.enquiryForm.Friends?.map((item, index) => {
                                return (
                                    <div style={{ maxWidth: "240px" }}>
                                        <h3 className="text-black-1 text-sm font-bold">{`Friend ${index + 1}`}</h3>
                                        <div className="mt-3 flex flex-col gap-1.5">
                                            <div className="grid grid-cols-3">
                                                <span className="col-span-1 font-semibold text-xs text-black-1">
                                                    Name
                                                </span>
                                                <span className="col-span-2 text-xs text-black-2" >
                                                    {item.name}
                                                </span>
                                            </div>
                                            <div className="grid grid-cols-3">
                                                <span className="col-span-1 font-semibold text-xs text-black-1">
                                                    Email
                                                </span>
                                                <span className="col-span-2 text-xs text-black-2">
                                                    {item.email}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            referFriendModal.enquiryForm.Message && referFriendModal.enquiryForm.Message.trim() != "" &&
                            <div className="mb-3">
                                <h3 className="text-black-1 text-sm font-bold">Message</h3>
                                <div className="center-detail-card flex flex-col gap-2 px-3">
                                    <p className="font-medium text-sm">
                                        {referFriendModal.enquiryForm.Message}
                                    </p>
                                </div>
                            </div>
                        }
                        
                    </div>
                </div>
                <div className="flex absolute bottom-0 w-full flex-col gap-2">
                    <button
                        className={`enquire-next-btn ${!isVerified ? ' btn-disabled hover:btn-disabled ' : ' bg-rose hover:bg-darkrose '} flex items-center justify-center font-semibold text-base text-white`}
                        onClick={onNext}
                        style={{ marginTop: 0 }}
                        disabled={!isVerified}
                    >
                        {loader ? 'Submitting ...' : 'Submit'}
                    </button>
                </div>
            </div>
            <ReferFriendCardComponent />
        </React.Fragment>
    );
}

export default ReferFriendReview;
import React from "react";
import Countdown from "../../ProfileParent/Part/CountDown";
import { Button, FormControl, Stack, TextField, styled } from "@mui/material";
import axios from "axios";

const CustomFormControl = styled(FormControl)(({ theme, error }) => ({
    '& .MuiFilledInput-root': {
        fontSize: '16px',
        borderRadius: '6px',
        border: '1px solid #CDCFF9',
        overflow: 'hidden',
        backgroundColor: "#F3F4FF!important",
        fontFamily: "Nunito",
        ...(error && ({
            border: '1px solid red',
        })),
        '& .MuiFilledInput-input': {
            '&:hover, &:focus': {
                boxShadow: 'none',
            },
        },

    },
    '& .MuiInputLabel-filled': {
        fontFamily: "Nunito",
    }

}))

const ButtonCustomOutlined = styled(Button)({
    border: ' 2px solid #5A60EC',
    '&.MuiButton-outlined': {
        color: '#5A60EC',
        textTransform: 'none',
        fontWeight: '800',
        fontSize: '17px',
        borderRadius: '50px',
    },
    '&:hover, &:focus': {
        boxShadow: 'none',
        border: ' 2px solid #5A60EC',
        backgroundColor: 'transparent',
    },
})

const ButtonCustomContained = styled(Button)({
    border: ' 2px solid #5A60EC',
    '&.MuiButton-contained': {
        color: '#ffffff',
        textTransform: 'none',
        fontWeight: '800',
        fontSize: '17px',
        borderRadius: '50px',
        backgroundColor: '#5A60EC',
        padding: '7px 21px',
    },
    '&:hover, &:focus': {
        boxShadow: 'none',
        border: ' 2px solid #5A60EC',
        backgroundColor: '#5A60EC',
    },
})

const VerificationOTPCode: React.FC<{
    startTime: boolean
    guid: undefined
    currentEmail: string
    password: string
    setIsSendOTP: React.Dispatch<React.SetStateAction<boolean>>
    resendCode: () => void
    enquiryDetail: any
    postCode: string | number
    closeModal: () => void
    enquiryType: number
}> = (props) => {
    const [valOTP, setValOTP] = React.useState("")
    const [parentId, setParentId] = React.useState(0)

    const assignParentToEnquiry = (parentId, enquiryId) => {
        let headers = {
            "Content-Type": "application/json",
        };
        document.getElementById('signupOTPError').style.display = "none"
        document.getElementById('errors').innerHTML = ""

        axios.post('/enquiry/assignparenttoenquiry', JSON.stringify({
            parentId,
            enquiryId,
            enquiryType: props.enquiryType
        }), { headers: headers })
            .then((response: any) => {
                props.closeModal();
            })
            .catch((error) => {
                document.getElementById('errors').style.display = "block"
                document.getElementById('errors').innerHTML = error.response?.data[0]
            })
            .finally(() => "")
    }


    const verifyOTP = (event) => {
        event.preventDefault();
        const payload = {
            firstName: props.enquiryDetail.profile.firstName,
            lastName: props.enquiryDetail.profile.lastName,
            email: props.currentEmail,
            password: props.password,
            verificationId: props.guid,
            otpCode: valOTP,
            postCode: Number(props.postCode),
            PhoneNumber: props.enquiryDetail.profile.phone,
        }
        document.getElementById('errors').style.display = "none"

        fetch('/parentaccount/registerparent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.Data) {
                    setParentId(data.Data.id)
                    assignParentToEnquiry(data.Data.id, props.enquiryDetail.formSubmitData.enquiryId)
                } else {
                    document.getElementById('errors').style.display = "block"
                    document.getElementById('errors').innerHTML = data.Errors[0]
                }
            })
            .catch(error => {
                console.error(error);
            });

    }


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            verifyOTP(event);
        }
    };

    return (<div className="mb-4 text-[#5A5A5A]" style={{ color: '#5a5a5a', fontWeight: 500, fontSize: '16px' }}>
        <div className="mb-[40px]  font-extrabold text-[26px]" style={{ color: '#272727' }}>Enter 6 digit code</div>
        <div className="mb-4">You are almost there!</div>
        <div className="mb-4">{`A verification code has been sent to ${props.currentEmail}.`}</div>
        <div id="resetOTPCount" className="mb-4 flex justify-center font-semibold text-[#5A5A5A]">
            <Countdown startTime={props.startTime} key={props.guid} />
        </div>
        <form onSubmit={verifyOTP}>
            <CustomFormControl fullWidth>
                <TextField
                    label="OTP Code"
                    type={'text'}
                    value={valOTP}
                    variant="filled"
                    onChange={(event) => setValOTP(event.target.value)}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                        disableUnderline: true,
                    }}
                />
            </CustomFormControl>

            <p id="errors" className="font-semibold text-[#ED033B]"></p>
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ marginTop: '20px' }} gap={'10px'}>
                <ButtonCustomOutlined variant="outlined" size="large" onClick={() => props.setIsSendOTP(false)}>
                    Cancel
                </ButtonCustomOutlined>
                <ButtonCustomContained variant="contained" size="large" type="submit">
                    Verify
                </ButtonCustomContained>
            </Stack>
        </form>
        <div className="mt-3 w-full flex justify-center">
            <button id="resetOTPResendSaveData"
                onClick={() => {
                    props.resendCode()
                    document.getElementById('resetOTPError').style.display = "none"
                }}
                className="text-center text-berry hover:text-darkberry font-bold">
                <u>Resend code</u>
            </button>
        </div>
    </div>)
}

export default VerificationOTPCode;
import axios from "axios";

export const getBaseUrl = () => {
    return window.location.href.substring(0, window.location.href.indexOf("/", window.location.href.indexOf("//") + 2));
};



export const getEmailInfo = () => {
    return new Promise(async (resolve, reject) => {
        axios
            .get('/profile/parentgetemail')
            .then(
                (response) => {
                    resolve(response?.data);
                },
                (err) => {
                    reject(err);
                }
            )
            .catch((error) => {
                reject(error);
            });
    });
}


export const getParentProfile = () => {
    return new Promise(async (resolve, reject) => {
        axios
            .get('/profile/parentgetprofile')
            .then(
                (response) => {
                    resolve(response?.data);
                },
                (err) => {
                    reject(err);
                }
            )
            .catch((error) => {
                reject(error);
            });
    });
}
export const getParentCCSResult = () => {
    return new Promise(async (resolve, reject) => {
        axios
            .get('/parentportal/parentgetccs')
            .then(
                (response) => {
                    resolve(response?.data);
                },
                (err) => {
                    reject(err);
                }
            )
            .catch((error) => {
                reject(error);
            });
    });
}
export const getDataSuburbAutocomplete = async (term) => {
    return new Promise(async (resolve, reject) => {
        axios
            .get('/search/locationautocomplete', {
                params: {
                    term: term
                }
            })
            .then(
                (response) => {
                    resolve(response?.data);
                },
                (err) => {
                    reject(err);
                }
            )
            .catch((error) => {
                reject(error);
            });
    });
};



import * as React from "react";
import { ENQUIRY_STATE } from "../EnquiryV2Component"
import ReferFriendCardComponent from "./ReferFriendCard";

interface IAddMessageComponent {
    [key: string]: any
}

export const AddMessageComponent = (props: IAddMessageComponent) => {

    const { enquiryDetail } = props
    const { referFriendModal } = enquiryDetail;
    const [referFriendModalItem, setReferFriendModal] = React.useState(referFriendModal)
    /* 
        --------------------
        Handlers function
        ---------------------
    */
    const handlerInputChange = (e) => {
        let updatedData = {...referFriendModalItem};
        updatedData.enquiryForm.Message = e.target.value;
        setReferFriendModal(updatedData);
    }

    const onNext = () => {
        let payload = {
            currentState: ENQUIRY_STATE.REFER_FRIEND_MESSAGE,
            nextState: ENQUIRY_STATE.REFER_FRIEND_REVIEW,
            data: referFriendModalItem
        }
        props.onNext(payload)
    };

    return (
        <React.Fragment>
            <div className="flex flex-col justify-between h-full w-full lg:max-w-320 h-428p pt-3 lg:pt-0"
                style={{ height: "428px" }}>
                <h3 className="text-black-1 font-bold text-xl text-center">
                    Refer a friend
                </h3>
                <div className="h-full mt-8">
                    <div className="font-bold text-sm text-black-1">Add a message</div>
                    <textarea className="text-black-2 mt-2 text-sm w-full"
                        name="message"
                        onChange={handlerInputChange}
                        value={referFriendModalItem?.enquiryForm?.Message}
                        placeholder="Hello! I just found this amazing child centre and thought you would be interested..." rows={6}></textarea>
                </div>
                <div className="flex flex-col" style={{ gap: "24px" }}>

                    <button className="enquire-next-btn bg-rose flex items-center justify-center font-semibold text-base text-white" onClick={onNext}
                        style={{ marginTop: "0px" }}>
                        Next
                    </button>
                </div>
            </div>
            <ReferFriendCardComponent />
        </React.Fragment >
    );
}

export default AddMessageComponent;

import * as React from "react";
import { ENQUIRY_STATE } from "../EnquiryV2Component"
import { Icon } from '@iconify/react';
import ReferFriendCardComponent from "./ReferFriendCard";

interface IAddFriendComponent {
    [key: string]: any
}

const referFriendModal = {
    enquiryForm: {
        EnquiryId: 0,
        Friends: [
            {
                "name": '',
                "email": ''
            }
        ],
        Message: ''
    },
    isValidData: true
}

export const AddFriendComponent = (props: IAddFriendComponent) => {

    const [items, setItem] = React.useState(JSON.parse(JSON.stringify(referFriendModal)))
    
    React.useEffect(()=>{

        return () => {
            setItem(referFriendModal)
        }
    })
    /* 
        --------------------
        Handlers function
        ---------------------
    */

    const handlerInputChange = (e, index) => {
        let updatedItem = { ...items }
        updatedItem.enquiryForm.Friends[index][e.target.name] = e.target.value;
        setItem(updatedItem)
    }

    const onNext = () => {
        if (isFormValid()) {
            setItem({...items, isValidData: true})
            let payload = {
                currentState: ENQUIRY_STATE.REFER_FRIEND_ADD,
                nextState: ENQUIRY_STATE.REFER_FRIEND_MESSAGE,
                data: items
            }
            props.onNext(payload)
        }
    };

    const onAddMoreFriends = () => {
        let updatedItem = { ...items };
        updatedItem.enquiryForm.Friends.push({
            "name": '',
            "email": ''
        });
        setItem(updatedItem)
    };

    const onRemoveFriends = (index) => {
        let updatedItem = { ...items };
        updatedItem.enquiryForm.Friends.splice(index, 1);
        setItem(updatedItem)
    }

    /* 
        --------------------
        Helper function
        ---------------------
    */
    const isFormValid = () => {
        let isValidData = true;
        setItem({...items, isValidData: true})
        items.enquiryForm.Friends.map((item)=> {
            if (item.name == null || item.name == '') {
                isValidData = false;
                setItem({...items, isValidData: false})
            }
            let emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (item.email == null || item.email == '' || !emailPattern.test(item.email.toLowerCase())) {
                isValidData = false;
                setItem({...items, isValidData: false})
            }
        })
        return isValidData
    }

    // console.log('ITEMS=',items);
    

    return (
        <React.Fragment>
            <div className="flex flex-col justify-between h-full w-full lg:max-w-320 h-428p pt-3 lg:pt-0"
                style={{ height: "428px" }}>
                <h3 className="text-black-1 font-bold text-xl text-center">Refer a friend</h3>
                <div className="flex flex-col gap-5 add-friends-container">
                    <div className="flex justify-start">
                        <button disabled={items.enquiryForm.Friends.length < 3 ? false : true} className="add-more-friends flex items-center justify-center font-medium text-xs" onClick={onAddMoreFriends}>
                            Add more friends<Icon icon="ic:baseline-plus"
                                className="text-sm"></Icon>
                        </button>
                    </div>
                    {
                        items.enquiryForm.Friends.map((item, index) => {
                            // console.log('item=',item);
                            
                            return (
                                <div key={index}>
                                    <div className="flex flex-col gap-2 px-1p">
                                        <input name="name"
                                            placeholder="Your friend's name *"
                                            className="enquire-input font-medium text-sm"
                                            onChange={(e) => handlerInputChange(e, index)}
                                            value={item.name}
                                        />
                                        <input name="email"
                                            placeholder="Your friend's email *"
                                            className="enquire-input font-medium text-sm"
                                            onChange={(e) => handlerInputChange(e, index)}
                                            value={item.email}
                                        />
                                    </div>
                                    {
                                        items.enquiryForm.Friends.length > 1 &&
                                        <div className="flex justify-end mt-1 mr-3">
                                            <button className="flex items-center justify-center text-xs text-red-500 font-semibold" onClick={() => onRemoveFriends(index)}>
                                                Remove</button>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        !items.isValidData &&
                        <span className="text-sm text-red-500">Please enter valid details.</span>
                    }
                </div>
                <button className="enquire-next-btn bg-rose flex items-center justify-center font-semibold text-base text-white" onClick={onNext}
                    style={{ marginTop: "0px" }}>
                    Next
                </button>
            </div>
            <ReferFriendCardComponent />
        </React.Fragment>
    );
}

export default AddFriendComponent;

import * as React from "react";
import { Icon } from '@iconify/react';


interface IEnquiryCardComponent {
    initialCareCentre: any;
    careCentres: any;
}

const EnquiryCardComponent = (props: IEnquiryCardComponent) => {

    const initialCareCenter = props.initialCareCentre;

    const careCentres = props.careCentres;
    const showInitialCareCenter = true;

    return (
        <div>
            {
                showInitialCareCenter &&
                <div className="bg-light-200 rounded-8 overflow-hidden hidden lg:flex flex-col"
                    style={{ maxWidth: "360px", overflow: "hidden" }}>
                    <div className="popup-image-container relative">
                        {
                            // initialCareCenter.hasImage &&
                            <img src={initialCareCenter.imageUrl} className="h-full w-full object-cover"
                                alt={initialCareCenter.name} />
                        }
                    </div>
                    <div className="enquire-popup-details flex flex-col gap-3 relative">
                        <div className="flex gap-4 text-xs mb-0.5 text-black-1">
                            <div className="flex gap-1 items-center">
                                <Icon icon="iconamoon:star-fill"
                                    className="text-yellow text-xs"></Icon>
                                <span className="font-semibold">{initialCareCenter.avgRating}</span><span>{initialCareCenter.reviewCountCombined}</span>
                            </div>
                            {
                                initialCareCenter.hasVacancies &&
                                <div className="flex gap-1 items-center">
                                    <Icon icon="teenyicons:tick-circle-solid"
                                        className="text-darkgreen text-xs"></Icon>
                                    <span>Vacancies</span>
                                </div>
                            }
                            <div className="flex gap-1 items-center">
                                <Icon icon="material-symbols:shield-outline"
                                    className="text-skyblue text-sm"></Icon>
                                <span><span>{initialCareCenter.nqsRating}</span> NQS</span>
                            </div>
                        </div>
                        <div className="flex flex-col mb-1.5">
                            <span className="text-black-1 font-bold text-lg leading-19p mb-1.5" dangerouslySetInnerHTML={{ __html: initialCareCenter.name }}></span>
                            <span className="text-black-2 text-xxs">{initialCareCenter.address}</span>
                        </div>
                        {
                            initialCareCenter.dailyAverageFee != '' &&
                            <div className="text-xs text-black-1 font-bold">
                                <span>{initialCareCenter.avgPricePerday}</span> <span className="font-normal">avg. price/day</span>
                            </div>
                        }


                        {/* @* <button className="absolute bottom-14 right-14 heart-icon"
                x-bind:className="`f-${initialCareCenter.centreUserIdHash} ${initialCareCenter.isFavourite ? 'active' : 'false'}`"
                x-bind:data-favourite-c-v3="initialCareCenter.isFavourite ? 'true':'false'"
                x-bind:data-userIdHash="initialCareCenter.centreUserIdHash"
                // onclick="favouriteTracker.onFavouriteClickCentreProfilev3WithoutParameter(this)"
                >
            <Icon icon="ph:heart-light" className="text-xl text-light-600 heart-outline"></Icon>

            <Icon icon="ph:heart-fill" className="text-xl text-rose heart-filled"></Icon>
        </button>*@ */}

                    </div>
                </div>

            }
            {
                careCentres && careCentres.length > 0 && careCentres.filter(x => x.active).length > 0 &&
                <div>
                    {
                        !showInitialCareCenter && careCentres && careCentres.length > 0 && careCentres.filter(x => x.active).length == 1 &&
                        <div className="bg-light-200 rounded-8 overflow-hidden hidden lg:flex flex-col" style={{ maxWidth: "360px", overflow: "hidden" }}>
                            <div className="popup-image-container relative">
                                {
                                    careCentres && careCentres.length > 0 && careCentres.filter(x => x.active)[0].hasImage &&
                                    <img src={careCentres && careCentres.length > 0 && careCentres.filter(x => x.active)[0].imageUrl} className="h-full w-full object-cover" alt={careCentres && careCentres.length > 0 && careCentres.filter(x => x.active)[0].name} />
                                }
                            </div>
                            <div className="enquire-popup-details flex flex-col gap-4 relative">
                                <div className="flex gap-4 text-xs text-black-1">
                                    <div className="flex gap-1 items-center">
                                        <Icon icon="iconamoon:star-fill" className="text-yellow text-xs"></Icon>
                                        <span className="font-semibold">{careCentres && careCentres.length > 0 && careCentres.filter(x => x.active)[0].avgRating}</span>
                                        <span>{careCentres && careCentres.length > 0 && `(${careCentres.filter(x => x.active)[0].reviewCountCombined})`}</span>
                                    </div>
                                    {
                                        careCentres && careCentres.length > 0 && careCentres.filter(x => x.active)[0].hasVacancies &&
                                        <div className="flex gap-1 items-center">
                                            <Icon icon="teenyicons:tick-circle-solid" className="text-darkgreen text-xs"></Icon>
                                            <span>Vacancies</span>
                                        </div>
                                    }

                                    <div className="flex gap-1 items-center">
                                        <Icon icon="material-symbols:shield-outline" className="text-skyblue text-sm"></Icon>
                                        <span><span>{careCentres && careCentres.length > 0 && careCentres.filter(x => x.active)[0].nqsRating}</span> NQS</span>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-black-1 font-bold text-lg leading-tight" dangerouslySetInnerHTML={{ __html: careCentres && careCentres.length > 0 && careCentres.filter(x => x.active)[0].name }}></span>
                                    <span className="text-black-2 text-xxs">{careCentres && careCentres.length > 0 && careCentres.filter(x => x.active)[0].address}</span>
                                </div>
                                {
                                    careCentres && careCentres.length > 0 && careCentres.filter(x => x.active)[0].dailyAverageFee &&
                                    <div className="text-xs text-black-1 font-bold">
                                        <span>{'$' + (careCentres && careCentres.length > 0 && careCentres.filter(x => x.active)[0].avgPricePerday)}</span>
                                        <span className="font-normal">avg. price/day</span>
                                    </div>
                                }

                                {/* @* <button className="absolute bottom-14 right-14 heart-icon" x-bind:className="`f-${careCentres && careCentres.length > 0 &&  careCentres.filter(x=>x.active)[0].centreUserIdHash} ${careCentres && careCentres.length > 0 &&  careCentres.filter(x=>x.active)[0].isFavourite ? 'active' : ''}`" x-bind:data-favourite-c-v3="careCentres && careCentres.length > 0 &&  careCentres.filter(x=>x.active)[0].isFavourite ? 'true' : 'false'" x-bind:data-userIdHash="careCentres && careCentres.length > 0 &&  careCentres.filter(x=>x.active)[0].centreUserIdHash" onclick="favouriteTracker.onFavouriteClickCentreProfilev3WithoutParameter(this)">
                <Icon icon="ph:heart-light" className="text-xl text-light-600 heart-outline"></Icon>
                <Icon icon="ph:heart-fill" className="text-xl text-rose heart-filled"></Icon>
            </button>*@ */}
                            </div>
                        </div>
                    }

                </div>
            }

            {
                !showInitialCareCenter && careCentres.filter(x => x.active).length > 1 &&
                <div className=" rounded-8 centre-container overflow-hidden w-full hidden lg:flex flex-col gap-2.5 justify-center" style={{ maxWidth: "360px", overflow: "hidden" }} >
                    {careCentres.map(function (item, i) {
                        return (
                            <>
                                <div key={i}>
                                    {
                                        item.active &&
                                        <div className="popup-centres-card rounded-10 bg-light-200 flex gap-4 items-center">
                                            <div className="centre-image-container relative shadow-small">
                                                {
                                                    item.hasImage &&
                                                    <img src={item.imageUrl} className="object-cover h-full w-full" alt={item.name} />
                                                }

                                                <div className="white-badge">
                                                    <div className="flex h-full w-full gap-1 items-center">
                                                        <Icon icon="iconamoon:star-fill" className="text-yellow text-xs"></Icon>
                                                        <span className="font-semibold">{item.avgRating}</span><span>(<span>{item.reviewCountCombined}</span>)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-1">
                                                <h5 className="font-bold text-black-1" dangerouslySetInnerHTML={{ __html: item.name }}></h5>
                                                <span className="text-xs text-black-3">{item.address}</span>
                                                {
                                                    item.dailyAverageFee > 0 &&
                                                    <div className="text-xss mt-1 text-black-1 font-normal"><span className="font-bold">{'$' + item.avgPricePerday}</span> avg price / day</div>
                                                }
                                                <div className="flex gap-3 text-xss">
                                                    {
                                                        item.hasVacancies &&
                                                        <div className="flex gap-1 items-center">
                                                            <Icon icon="teenyicons:tick-circle-solid" className="text-darkgreen text-sm"></Icon>
                                                            <span>Vacancies</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        )
                    })}

                </div>
            }
        </div>

    );
}

export default EnquiryCardComponent;
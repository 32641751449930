
import * as React from "react";
import { ENQUIRY_STATE } from "../EnquiryV2Component"
import { Icon } from '@iconify/react';

interface IReferFriendCardComponent {
    [key: string]: any
}

export const ReferFriendCardComponent = (props: IReferFriendCardComponent) => {

    return (
        <div className="bg-sky-100 rounded-8 overflow-hidden hidden lg:flex flex-col w-full"
             style={{maxWidth: "360px", overflow: "hidden"}}>
            <div className="popup-image-container relative h-full w-full"
                 style={{height: "428px"}}>
                <img src="/img/RAF-cover.jpg"
                     className="h-full w-full object-cover"
                     loading="lazy"
                     alt="#" />
                <div className="free-childcare flex flex-col justify-end items-center absolute text-neon-yellow font-medium pl-5">
                    <img src="/img/RAF_badge_new.png"
                         className="h-full w-full object-contain"
                         loading="lazy"
                         alt="#" />
                </div>
            </div>
        </div>
    );
}

export default ReferFriendCardComponent;

import * as React from "react";
import { ENQUIRY_STATE } from "../EnquiryV2Component"
import axios from "axios";
interface IEnquiryCreateAccountComponent {
    [key: string]: any
}
type suburbObject = {
    value: string,
    label: string,
}

export const EnquiryCreateAccountComponent = (props: IEnquiryCreateAccountComponent) => {
    const [open, setOpen] = React.useState(false);
    const [pageStack, setPageStack] = React.useState([]);
    const [layoutModalMVC, setLayoutModelMVC] = React.useState<{
        CurrentUserType: number;
        IsMiniSite: boolean;
        IsPublicSite: boolean;
        EnableVacancyAlertFeature: any;
        IsParentPlaybookEnabled: boolean;
        BaseUrl: string;
    }>({
        CurrentUserType: 0,
        IsMiniSite: false,
        IsPublicSite: false,
        EnableVacancyAlertFeature: null,
        IsParentPlaybookEnabled: false,
        BaseUrl: "",
    })
    const [pageObj, setPageObj] = React.useState({
        suburb: "",
        suburbId: "",
        password: "",
        confirmPassword: "",
        showAutocompleteContainer: false,
        autoCompleteData: []
    });
    const [errorsFields, setErrorFields] = React.useState({
        suburb: "",
        password: "",
        confirmPassword: "",
    })
    const url = (window as any).c4k.urls.locationAutocomplete;
    React.useEffect(() => {
        getSubrubList()

    }, [])

    // API Code
    const getSubrubList = () => {

    }

    /* 
      --------------------
      Handlers function
      ---------------------
    */
    const handlerInputChange = (e) => {
        setPageObj({
            ...pageObj,
            [e.target.name]: e.target.value
        })
        setErrorFields({
            ...errorsFields,
            [e.target.name]: ''
        })
    }

    const handlerSuburbChange = (e) => {
        setPageObj({
            ...pageObj,
            [e.target.name]: e.target.label,
            suburbId: e.target.value
        })
        setErrorFields({
            ...errorsFields,
            [e.target.name]: ''
        })
    }

    React.useEffect(() => {
        if (typeof window !== "undefined") {
            setLayoutModelMVC({
                CurrentUserType: window.CurrentUserType,
                IsMiniSite: window.IsMiniSite,
                IsPublicSite: window.IsPublicSite,
                EnableVacancyAlertFeature: window.EnableVacancyAlertFeature,
                IsParentPlaybookEnabled: window.IsParentPlaybookEnabled,
                BaseUrl: window.BaseUrl,
            })
        }
    }, [])

    const handleAutoComplete = (e) => {

        const inputValue = e.target.value.trim().toLowerCase();

        let queryUrl = url + "?term=" + inputValue;

        if (inputValue.length > 2) {

            return new Promise<Array<suburbObject>>((resolve, reject) => {
                axios.get(queryUrl).then((response: any) => {

                    // Format to an array that matches the value label structure
                    let results = response.data.map(suburb => {
                        return {
                            value: suburb.key,
                            label: suburb.label,
                            postcode: suburb.postcode
                        }
                    })
                    setPageObj({
                        ...pageObj,
                        autoCompleteData: results,
                        showAutocompleteContainer: true
                    })
                    resolve(results)
                })
                    .catch(error => {
                        console.log('Suburb search error', error)
                    })
            })
        }
    }

    const selectAutoComplete = (item) => {
        setPageObj({
            ...pageObj,
            suburb: item.label,
            suburbId: item.value,
            showAutocompleteContainer: false
        })
    }

    const onSignin = () => {

        if (isFormValid()) {
            let payload = {
                currentState: ENQUIRY_STATE.CREATE_ACCOUNT,
                nextState: ENQUIRY_STATE.COMMENTS,
                data: pageObj
            }
            props.onNext(payload)
        }
    };

    const isFormValid = () => {
        let isFormValid = true;
        let errorsFields: any = {
            suburb: "",
            password: "",
            confirmPassword: "",
        }
        const { suburb, password, confirmPassword } = pageObj
        if (suburb == null || suburb == '') {
            errorsFields['suburb'] = { message: "Required Feild" };
            isFormValid = false;
        }
        if (password == null || password == '') {
            errorsFields['password'] = { message: "Required Feild" };
            isFormValid = false;
        }
        if (confirmPassword == null || confirmPassword == '') {
            errorsFields['confirmPassword'] = { message: "Required Feild" };
            isFormValid = false;
        }
        if (confirmPassword && password) {
            if (confirmPassword.toLocaleLowerCase() !== password.toLocaleLowerCase()) {
                errorsFields['confirmPassword'] = { message: "Required Feild" };
                isFormValid = false;
            }
        }

        setErrorFields(errorsFields)
        return isFormValid
    }

    const authButtonClick = () => {
        const currentURL = window.location.href;
        const containsChildCareProviders = currentURL.includes("/childcareproviders");

        if (containsChildCareProviders) {

            window.open(`${layoutModalMVC.BaseUrl}/signin`, '_blank');
        } else {

            if (pageStack.length <= 0) {

                setPageStack(prevStack => [...prevStack, "emailPage"]);
            }


            const authModal = document.getElementById('authModal');
            if (authModal) {
                authModal.classList.remove('hidden');
                setOpen(false)
            }
        }
    };

    return (
        <div className="flex flex-col items-center w-full lg:max-w-320 enquire-form-side">
            <div className="step-meter bg-gray relative rounded-25">
                <span className="bg-gradient-blue absolute top-0 left-0 h-full rounded-25 w-60p"></span>
            </div>
            <div className="h-full relative w-full flex flex-col justify-between">
                <div>
                    <div className="w-full">
                        <div className="font-bold text-lg text-black-1">
                            Create an account
                        </div>
                    </div>
                    <div className="flex flex-col w-full mt-3 gap-3">
                        <div className="relative">
                            <input
                                name="suburb"
                                placeholder="Your suburb*"
                                className={`enquire-input font-medium text-sm ${errorsFields.suburb ? ' cp-input-validation ' : ''}`}
                                value={pageObj.suburb}
                                onKeyUp={handleAutoComplete}
                                onChange={handlerSuburbChange}
                            />
                            {
                                pageObj.showAutocompleteContainer &&
                                <div className="centre-select-dropdown">
                                    <ul>
                                        {
                                            pageObj.autoCompleteData.map((item, i) => {
                                                return (
                                                    <div key={i}>
                                                        <li onClick={() => selectAutoComplete(item)}>{item.label}</li>
                                                    </div>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                        <input
                            name="password"
                            type="password"
                            placeholder="Password*"
                            className={`enquire-input font-medium text-sm border-none ${errorsFields.password ? ' cp-input-validation ' : ''}`}
                            value={pageObj.password}
                            onChange={handlerInputChange}
                        />
                        <input
                            name="confirmPassword"
                            type="password"
                            placeholder="Confirm Password*"
                            className={`enquire-input font-medium text-sm border-none ${errorsFields.confirmPassword ? ' cp-input-validation ' : ''} `}
                            value={pageObj.confirmPassword}
                            onChange={handlerInputChange}
                        />
                    </div>
                </div>
                <div className="bottom-0 w-full">
                    <div className="mt-6 flex justify-center w-full gap-1 text-black-1 text-sm font-normal">
                        <span>Already have an account?</span>
                        <span className="text-rose font-semibold" onClick={authButtonClick}>Sign In</span>
                    </div>
                    <button className="enquire-next-btn bg-rose hover:bg-darkrose flex items-center justify-center font-semibold text-base text-white mt-5" onClick={onSignin}
                        style={{ marginTop: "20px" }}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EnquiryCreateAccountComponent;
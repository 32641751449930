import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility } from "@mui/icons-material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Autocomplete, Box, Button, FilledInput, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, Stack, TextField, Typography, styled } from "@mui/material";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import VerificationOTPCode from "../../Shared/VerificationOTPCode";
import { getDataSuburbAutocomplete } from "../../ProfileParent/Services/ProfileParent.api";
interface IEnquirySaveDetailComponent {
    [key: string]: any
}
const CustomFormControl = styled(FormControl)(({ theme, error }) => ({
    '& .MuiFilledInput-root': {
        fontSize: '16px',
        borderRadius: '6px',
        border: '1px solid #CDCFF9',
        overflow: 'hidden',
        backgroundColor: "#F3F4FF!important",
        fontFamily: "Nunito",
        ...(error && ({
            border: '1px solid red',
        })),
        '& .MuiFilledInput-input': {
            '&:hover, &:focus': {
                boxShadow: 'none',
            },
        },

    },
    '& .MuiInputLabel-filled': {
        fontFamily: "Nunito",
    }

}))

const ButtonCustomContained = styled(Button)({
    border: ' 2px solid #FA3A7F',
    '&.MuiButton-contained': {
        color: '#ffffff',
        textTransform: 'none',
        fontWeight: '800',
        fontSize: '17px',
        borderRadius: '50px',
        backgroundColor: '#FA3A7F',
        padding: '7px 21px',
        fontFamily: "Nunito",
    },
    '&:hover, &:focus': {
        boxShadow: 'none',
        border: ' 2px solid #FA3A7F',
        backgroundColor: '#FA3A7F',
    },
})

const validationSchema = yup.object({
    email: yup
        .string()
        .trim()
        .email('Enter a valid email')
        .required('Email is required'),
    suburb: yup.string().required('Suburb is required'),
    password: yup.string().required('Please enter a new password.')
        .trim()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/, 'The password must contain 8 characters, at least one letter and one number'),
});

const fullName = (firstName?: string, lastName?: string) => {
    if (!firstName && !lastName) {
        return 'N/A'
    }

    if (!firstName || !lastName) {
        return <>{firstName || lastName}</>
    }

    return (
        <>
            {firstName} {lastName}
        </>
    )
}


const handlePhoneChange = (value) => {
    let cleaned = ('' + value).replace(/\D/g, '');

    if (cleaned.length !== 10) {
        throw new Error("Invalid input: phone number must contain exactly 10 digits.");
    }

    let part1 = cleaned.slice(0, 3);
    let part2 = cleaned.slice(3, 6);
    let part3 = cleaned.slice(6);

    let formatted = `(${part1}) ${part2}-${part3}`;

    return formatted;
};


const EnquireSaveDetail = (props: IEnquirySaveDetailComponent) => {
    const [guid, setGuid] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const [optionSuburb, setOptionSuburb] = React.useState([])
    const [startTime, setStartTime] = React.useState(false)
    const [valSuburb, setValSuburb] = React.useState({})
    const [isSendOTP, setIsSendOTP] = React.useState(false)
    const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
    const [isSaveInfoChecked, setIsSaveInfoChecked] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [debouncedTerm, setDebouncedTerm] = React.useState(searchTerm);

    const {
        handleSubmit,
        getValues,
        setValue,
        control,
        formState: { errors },
    } = useForm<any>({
        resolver: yupResolver(validationSchema),
        mode: 'all'
    })

    React.useEffect(() => {
        if (props.enquiryDetail.profile.email) {
            setValue('email', props.enquiryDetail.profile.email)
        }
    }, [props.enquiryDetail.profile])



    const onSubmit = (values) => {
        try {
            setLoading(true)
            let formData = new FormData();
            formData.append('email', values.email);


            fetch(`/parentaccount/sendotp`, {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setGuid(data);
                        setStartTime(true);
                        setIsSendOTP(true)
                    }

                })
                .catch(error => {
                    console.error(error);
                    setIsSendOTP(false)
                })
                .finally(() => setLoading(false))
        }
        catch (error) {

        }

    }

    const handleSearchSuburb = async (event: React.SyntheticEvent, value: string, reason: string) => {
        if (!value) {
            setOptionSuburb([])
        }
        setSearchTerm(value)

    }

    React.useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.returnValue = 'Your details will not be saved if you cancel.';
            return 'Your details will not be saved if you cancel.';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const resendCode = () => {
        let formData = new FormData();
        formData.append('email', getValues('email'));

        fetch(`/parentaccount/sendotp`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setGuid(data);
                    setStartTime(true);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    React.useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedTerm(searchTerm);
        }, 500);

        return () => {
            clearTimeout(timerId);
        };
    }, [searchTerm]);


    React.useEffect(() => {
        const search = async () => {
            const data: any = await getDataSuburbAutocomplete(searchTerm);
            setOptionSuburb(data)
        };

        if (debouncedTerm) {
            search();
        }
    }, [debouncedTerm]);




    return <>
        <div className="enquire-form-side relative m-auto flex w-full flex-col items-center pt-3 lg:pt-0">
            {
                !isSendOTP ?
                    <>
                        <Typography sx={{
                            fontSize: '26px',
                            color: '#272727',
                            fontWeight: 800,
                            marginBottom: '29px'
                        }}>Save Details</Typography>
                        <Box sx={{ fontSize: '16px', color: '#5E5A6F', marginBottom: '29px' }}>
                            <Stack justifyContent="center" gap="10px">
                                <Stack direction="row" alignItems="center" gap="10px">
                                    <Typography sx={{
                                        fontWeight: 700
                                    }}>Your Name</Typography>
                                    <Typography sx={{
                                        fontWeight: 500
                                    }}>{fullName(props.enquiryDetail.profile.firstName, props.enquiryDetail.profile.lastName)}</Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center" gap="10px">
                                    <Typography sx={{
                                        fontWeight: 700
                                    }}>Phone Number</Typography>
                                    <Typography sx={{
                                        fontWeight: 500
                                    }}>{props.enquiryDetail.profile.phone ? handlePhoneChange(props.enquiryDetail.profile.phone) : "N/A"}</Typography>
                                </Stack>

                                <Typography className="font-bold italic">You can change your information in the Account Setting later.</Typography>
                            </Stack>

                        </Box>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Controller control={control} name="email" render={({ field }) => {
                                        return (
                                            <CustomFormControl fullWidth error={!!errors.email}>
                                                <TextField
                                                    error={!!errors.email}
                                                    label="Email"
                                                    {...field}
                                                    variant="filled"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                />
                                                <FormHelperText
                                                    error={!!errors.email}
                                                >
                                                    {errors.email &&
                                                        `${errors.email.message}`}
                                                </FormHelperText>
                                            </CustomFormControl>
                                        )
                                    }} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Controller control={control} name="suburb" render={({ field }) => {
                                        return (
                                            <CustomFormControl fullWidth error={!!errors.suburb}>
                                                <Autocomplete
                                                    value={valSuburb || ""}
                                                    autoComplete={false}
                                                    disableClearable
                                                    options={optionSuburb}
                                                    getOptionLabel={(option) => option.label || ""}
                                                    onInputChange={handleSearchSuburb}
                                                    onChange={(event: any, newValue: any) => {
                                                        setValue('suburb', newValue?.postcode);
                                                        setValSuburb(newValue)
                                                    }}
                                                    onBlur={field.onBlur}
                                                    renderInput={(params) => <TextField {...params}
                                                        label="Town/Suburb"
                                                        variant="filled"
                                                        error={!!errors.suburb}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            disableUnderline: true,
                                                            endAdornment: (
                                                                <>
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }} />}


                                                />
                                                <FormHelperText
                                                    error={!!errors.suburb}
                                                >
                                                    {errors.suburb &&
                                                        `${errors.suburb.message}`}
                                                </FormHelperText>
                                            </CustomFormControl>
                                        )
                                    }} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Controller control={control} name="password" render={({ field }) => {
                                        return (
                                            <CustomFormControl fullWidth variant="filled" error={!!errors.oldPassword}>
                                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                <FilledInput
                                                    {...field}
                                                    id="outlined-adornment-password"
                                                    type={showCurrentPassword ? 'text' : 'password'}
                                                    disableUnderline
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setShowCurrentPassword((show) => !show)}
                                                                edge="end"
                                                            >
                                                                {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                <FormHelperText
                                                    error={!!errors.password}
                                                >
                                                    {errors.password &&
                                                        `${errors.password.message}`}
                                                </FormHelperText>
                                            </CustomFormControl>
                                        )
                                    }} />
                                </Grid>
                            </Grid>

                            <div className="flex self-start items-center gap-1.5 text-[14px] mt-6 cursor-pointer" style={{ color: '#707070' }}>
                                <label
                                    htmlFor="isSaveInfoChecked"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setIsSaveInfoChecked(!isSaveInfoChecked);
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        name="isSaveInfoChecked"
                                        checked={isSaveInfoChecked}
                                        className="cursor-pointer text-black-2 mb-0.5 mr-1"
                                        onChange={(e) => {
                                            setIsSaveInfoChecked(e.target.checked);
                                        }}
                                    />
                                    I have read and agreed to the {' '}
                                    <a href="https://staging.careforkids.com.au/home/termsandconditions" target="_blank" className="underline">terms and conditions</a>
                                    {' '}and{' '}
                                    <a href="https://staging.careforkids.com.au/home/privacypolicy" target="_blank" className="underline">privacy policy</a>
                                </label>
                            </div>

                            <CustomFormControl fullWidth sx={{ marginTop: '29px' }}>
                                <ButtonCustomContained variant="contained" size="large" type="submit" disabled={!isSaveInfoChecked}>
                                    Continue
                                </ButtonCustomContained>
                            </CustomFormControl>

                        </form>
                    </> :
                    <>
                        <VerificationOTPCode
                            enquiryType={props.enquiryType}
                            setIsSendOTP={setIsSendOTP}
                            startTime={startTime}
                            password={getValues('password')}
                            postCode={getValues('suburb')}
                            guid={guid}
                            currentEmail={getValues('email')}
                            resendCode={resendCode}
                            closeModal={props.closeModal}
                            enquiryDetail={props.enquiryDetail} />
                    </>
            }
        </div>


    </>
}

export default EnquireSaveDetail